import { ComponentProps, Dispatch, ReactNode, SetStateAction } from 'react';

import { Button as Btn, ButtonProps as BtnProps } from '@headlessui/react';

import { cn } from '../../helpers/utils';

export type ButtonProps = ComponentProps<typeof Button>;

export const Button = ({
  className,
  variant,
  icon,
  big,
  ...props
}: BtnProps & {
  big?: boolean;
  variant?: 'default' | 'ghost';
  icon?: boolean;
}) => (
  <Btn
    {...props}
    className={cn(
      'disabled:bg-blue/40 disabled:text-dark/60',
      'center flex animate-fade justify-center rounded-md px-4 py-2 text-sm font-semibold uppercase transition-all',
      'bg-blue text-dark hover:bg-blue/80 focus:bg-blue/70',
      'outline-1 outline-offset-0 outline-blue duration-200 hover:outline-offset-8 hover:outline-transparent',
      variant === 'ghost' && 'bg-transparent text-blue hover:bg-blue/10 focus:bg-blue/15',
      icon && '!p-[5px]',
      big && 'w-full p-4 text-lg font-bold',
      className,
    )}
  />
);

export const Toggle = ({
  active,
  onClick,
  onChange,
  ...props
}: ComponentProps<typeof Button> & {
  active?: boolean;
  onChange?: Dispatch<SetStateAction<boolean>>;
}) => (
  <Button
    {...props}
    onClick={(e) => {
      onChange?.(!active);
      onClick?.(e);
    }}
  />
);

export type CardProps = ComponentProps<typeof Card>;

export const Card = ({ className, dark, ...props }: ComponentProps<'section'> & { dark?: boolean }) => (
  <section
    {...props}
    className={cn(
      dark ? 'bg-dark' : 'card-bg',
      'flex animate-fade flex-col items-start justify-start overflow-hidden border border-solid border-blue/50 backdrop-blur-2xl transition-all',
      className,
    )}
  />
);

export const Heading = ({
  children,
  aside,
  small,
  className,
}: ComponentProps<'div'> & { aside?: ReactNode; small?: boolean }) => (
  <div className={cn('flex w-full items-center justify-between gap-4 pb-4 md:pb-6 lg:pb-8', className)}>
    <h2 className={cn(small ? 'text-base' : 'text-xl font-semibold uppercase', 'leading-none')}>
      {children}
    </h2>
    <div className="grow self-end border-b border-blue" />
    {aside && <div>{aside}</div>}
  </div>
);
