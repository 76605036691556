export const en = {
  all: {
    head: {
      analytics: 'Analytics',
      dashboard: 'Dashboard',
      mint: 'Mint',
      auction: 'Auction',
      farm: 'Farm',
      connect: 'Connect Wallet',
      connected: 'Wallet',
    },
    dashboard: {
      all: 'all',
      cdp: 'cdp positions',
      holding: 'holding',
      farming: 'farming',
      cards: {
        'total-earn': 'Total Earn',
        'claimable-rewards': 'Claimable Rewards',
        'rewards-projection': '1 week rewards projection',
        'claim-all': 'Claim All',
        'global-health-factor': 'Global Health Factor',
        'health-factor': 'Health Factor:',
        select: 'Select',
        'total-assets': 'Total Assets',
        'cdp-details': 'CDP details',
        liquidation: 'Liquidation',
      },
    },
  },
}
