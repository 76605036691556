import { sha256 } from '@noble/hashes/sha256'
import * as secp from '@noble/secp256k1'
import { setUser } from '@sentry/react'
import {
  KeplrController,
  StationController,
  WalletName,
  ConnectedWallet,
  WalletType,
  WalletController,
} from 'cosmes/wallet'
import { toast } from 'react-toastify'
import { createStore } from 'zustand-x'

import { classicNetwork } from '../consts/networks'

export const TOAST_ID_CONNECT_WALLET = 'tid=connectWallet'

export async function createAndSignJWT(wallet: ConnectedWallet) {
  const privateKey = secp.etc.hashToPrivateKey?.(new TextEncoder().encode(wallet.address))
  const publicKey = Buffer.from(secp.getPublicKey(privateKey)).toString('base64')

  const header = JSON.stringify({
    alg: 'ES256K',
    typ: 'JWT',
  })

  const message = JSON.stringify({
    expiry: Math.floor(Date.now() / 1000) + 43200,
    publicKey,
    address: wallet.address,
  })

  const hash = sha256(Buffer.from(message, 'utf-8'))

  // convert private key to hex
  const signature = (await secp.signAsync(hash, privateKey)).toCompactHex()

  // convert to base64 header and message
  const headerBase64 = Buffer.from(header).toString('base64')
  const messageBase64 = Buffer.from(message).toString('base64')

  // create JWT
  return `${headerBase64}.${messageBase64}.${signature}`
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const controllers: Record<WalletName, WalletController> = {
  [WalletName.STATION]: new StationController(),
  [WalletName.KEPLR]: new KeplrController('a84795241575bc714244f61a6618ae81'),
  // [WalletName.LEAP]: new LeapController('a84795241575bc714244f61a6618ae81'),
  // [WalletName.COSMOSTATION]: new CosmostationController('a84795241575bc714244f61a6618ae81'),
}

type WalletState = {
  connectModalIsOpen: boolean
  jwt: string | undefined
  wallet: ConnectedWallet | undefined
  taxRate: string | undefined
  connection: { id: WalletName; type: WalletType } | undefined
}

const partialize = (state: WalletState): WalletState =>
  ({
    connectModalIsOpen: state.connectModalIsOpen,
    taxRate: state.taxRate,
    connection: state.connection,
  }) as WalletState

export const walletState = createStore('wallet')<WalletState>(
  {
    connectModalIsOpen: false,
    jwt: undefined,
    wallet: undefined,
    taxRate: undefined,
    connection: undefined,
  },
  {
    persist: {
      enabled: true,
      partialize,
      onRehydrateStorage: () => {
        return (state, error) => {
          if (!error) {
            setTimeout(() => {
              if (state?.connection) {
                connectWallet(state.connection.id, state.connection.type)
              }
            }, 200)
          }
        }
      },
      // onRehydrateStorage: rehydrate,
    },
  }
)
  .extendSelectors((state) => ({
    currentController: () => (state.wallet ? controllers[state.wallet?.id] : undefined),
    address: () => state.wallet?.address,
    network: () => state.wallet?.chainId,
  }))
  .extendActions((set) => ({
    wallet: async (wallet: ConnectedWallet | undefined) => {
      if (wallet) {
        await createAndSignJWT(wallet).then(set.jwt)
        set.connection({ id: wallet.id, type: wallet?.type })
      } else {
        set.connection(undefined)
        set.jwt(undefined)
      }
      set.wallet(wallet)
    },
  }))

// a bit of a chicken and egg situation here, needs to stay as a separate function
export function connectWallet(id: WalletName, type: WalletType) {
  const controller = controllers[id]
  if (controller) {
    Object.values(controllers).forEach((controller) => {
      if (controller.id !== id) {
        controller.disconnect([classicNetwork.id])
      }
    })
    setUser(null)
    const walletPromise = controller
      ?.connect(type, [
        {
          chainId: classicNetwork.id,
          rpc: classicNetwork.rpc,
          gasPrice: {
            amount: '28.325',
            denom: 'uluna',
          },
        },
      ])
      .then((wallets) => {
        const newWallet = wallets.get(classicNetwork.id)
        walletState.set.wallet(newWallet)
        setUser({
          id: newWallet?.address,
          controllerType: type,
          controller: id,
        })
        controller.onAccountChange(() => {
          connectWallet(id, type)
        })
        controller.onDisconnect(() => {
          setUser(null)
          walletState.set.wallet(undefined)
        })
      })

    toast.promise(
      walletPromise,
      { success: 'Wallet Connected', error: 'Connection failed' },
      { autoClose: 1000, toastId: TOAST_ID_CONNECT_WALLET }
    )
  }
}
export const disconnect = () => {
  Object.values(controllers).forEach((controller) => {
    controller.disconnect([classicNetwork.id])
  })
  setUser(null)
}

export const closeWalletConnectModal = () => {
  toast.dismiss(TOAST_ID_CONNECT_WALLET)
  walletState.set.connectModalIsOpen(false)
}

export const openWalletConnectModal = () => {
  walletState.set.connectModalIsOpen(true)
}

export const getAddress = () => {
  const address = walletState.get.address()
  if (!address) {
    openWalletConnectModal()
  }
  return address!
}

export const useAddress = walletState.use.address

export const useNetwork = walletState.use.network

export const useWallet = walletState.use.wallet
