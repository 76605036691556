import { lazy, Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Layout } from './ui/layout';

const Page = lazy(() => import('./ui/pages/page'));
const Mint = lazy(() => import('./ui/pages/mint'));
const Auction = lazy(() => import('./ui/pages/auction'));
const Dashboard = lazy(() => import('./ui/pages/dashboard'));
const Analytics = lazy(() => import('./ui/pages/analytics'));

const App = () => {
  // const [tutorialCompleted, setTutorialCompleted] = useLocalStorage('tutorial-complete', false);
  // useEffect(() => {
  //       driverObj.drive();
  //       // setTutorialCompleted(true);
  //     }, 1500);
  //     return () => {
  //       clearTimeout(to);
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tutorialCompleted]);
  return (
    <>
      <Layout>
        <Suspense fallback={'loading...'}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard/:mode?" element={<Dashboard />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/auction" element={<Auction />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/farm" element={<Page />} />
          </Routes>
        </Suspense>
      </Layout>
      <ToastContainer
        toastClassName="box bg-dark/40 border-blue border rounded-none text-blue backdrop-blur-md"
        position="bottom-right"
        autoClose={5000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={true}
        stacked={false}
        progressClassName="!bg-none"
      />
    </>
  );
};

export default App;
