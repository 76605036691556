import { FC, ReactNode } from 'react'

import * as Sentry from '@sentry/react'

export const ErrorBoundary: FC<{
  children: ReactNode
  message?: string
  errorChildren?: ReactNode
}> = ({ children, message, errorChildren }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div className="center h-full w-full flex-col gap-6 text-white">
          {errorChildren ?? message ?? (
            <>
              <p>This page encountered an error:</p>
              <pre className="rounded-lg bg-white/10 p-2 font-mono text-xs">
                {typeof error === 'string' ? error : (error?.toString?.() ?? null)}
              </pre>
            </>
          )}
          <div className="center gap-4"></div>
        </div>
      )}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
