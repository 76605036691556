import { PropsWithChildren } from 'react'

import { t } from 'i18next'
import { Link, useLocation } from 'react-router-dom'

import { cn } from '../../helpers/utils'
import { ErrorBoundary } from './errors'
import { WalletButton } from './wallet'

const links = [
  { url: '/dashboard', label: 'head.dashboard' },
  { url: '/mint', label: 'head.mint' },
  { url: '/auction', label: 'head.auction' },
  { url: '/analytics', label: 'head.analytics' },
  { url: '/farm', label: 'head.farm' },
]

export const Layout = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  return (
    <div className="w-[calc(100%-2rem)] max-w-[1400px]">
      <header className="flex items-center justify-between pb-3 pt-4">
        <Link to="/" id="logo" className="flex content-center items-center transition-all">
          <img src="/assets/logo-desktop.svg" className="max-w-1/3 hidden lg:flex" />
          <img src="/assets/logo-mobile.svg" className="flex lg:hidden" />
        </Link>
        <nav className="grid grid-cols-5 place-items-stretch gap-8 p-6 font-advent font-extrabold uppercase leading-5">
          {links.map((link) => (
            <Link key={link.url} to={link.url} className="group flex items-center justify-center text-center">
              <span
                className={cn(
                  'border-b-4 border-solid border-transparent px-2.5 transition-all',
                  location.pathname.startsWith(link.url) ? 'border-blue' : 'group-hover:border-blue/60'
                )}>
                {t(link.label)}
              </span>
            </Link>
          ))}
        </nav>
        <WalletButton />
      </header>
      <main className="grid grid-cols-1 gap-4 pb-10 pt-4 sm:grid-cols-2 md:grid-cols-4 md:gap-6 lg:grid-cols-6 lg:gap-10">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  )
}
