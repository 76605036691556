import { useEffect, useState } from 'react'

import { WalletController } from 'cosmes/wallet'
import { t } from 'i18next'
import { Copy, TrendUp } from 'iconsax-react'
import { capitalize } from 'lodash'
import { FaMobileScreen, FaPuzzlePiece } from 'react-icons/fa6'

import { classicNetwork } from '../../consts/networks'
import { copyToClipboard } from '../../helpers/utils'
import {
  closeWalletConnectModal,
  connectWallet,
  controllers,
  openWalletConnectModal,
  useAddress,
  walletState,
} from '../../state/wallet-state'
import { Button } from '../components/base'
import { Modal } from '../components/modal'

const CurrentWallet = () => {
  const wallet = walletState.use.wallet()
  const controller = walletState.use.currentController()
  return wallet ? (
    <div className="flex w-full flex-col items-start md:w-[700px] lg:w-[800px]">
      <p className="mb-4 font-medium">All Available Synthetic Assets</p>

      <div className="flex flex-row flex-wrap justify-between pb-8">
        <div className="flex w-full flex-row items-center justify-between gap-2 px-4 py-3 sm:w-1/2 sm:max-w-[340px]">
          <div className="flex flex-row items-center gap-4">
            <div>
              <img src="/assets/token.svg" className="w-8" alt="Token image" />
            </div>
            <div>
              <p className="mb-1 font-semibold text-blue">Token name</p>
              <p className="flex items-center gap-1 text-xs text-green">
                <TrendUp size={12} /> icona + %
              </p>
            </div>
          </div>
          <div className="font-medium text-blue">
            <p className="text-xs">Balance</p>
            <p className="">value</p>
          </div>
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-2 px-4 py-3 sm:w-1/2 sm:max-w-[340px]">
          <div className="flex flex-row items-center gap-4">
            <div>
              <img src="/assets/token.svg" className="w-8" />
            </div>
            <div>
              <p className="mb-1 font-semibold text-blue">Token name</p>
              <p className="flex items-center gap-1 text-xs text-green">
                <TrendUp size={12} /> icona + %
              </p>
            </div>
          </div>
          <div className="font-medium text-blue">
            <p className="text-xs">Balance</p>
            <p className="">value</p>
          </div>
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-2 px-4 py-3 sm:w-1/2 sm:max-w-[340px]">
          <div className="flex flex-row items-center gap-4">
            <div>
              <img src="/assets/token.svg" className="w-8" />
            </div>
            <div>
              <p className="mb-1 font-semibold text-blue">Token name</p>
              <p className="flex items-center gap-1 text-xs text-green">
                <TrendUp size={12} /> icona + %
              </p>
            </div>
          </div>
          <div className="font-medium text-blue">
            <p className="text-xs">Balance</p>
            <p className="">value</p>
          </div>
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-2 px-4 py-3 sm:w-1/2 sm:max-w-[340px]">
          <div className="flex flex-row items-center gap-4">
            <div>
              <img src="/assets/token.svg" className="w-8" />
            </div>
            <div>
              <p className="mb-1 font-semibold text-blue">Token name</p>
              <p className="flex items-center gap-1 text-xs text-green">
                <TrendUp size={12} /> icona + %
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1 font-medium text-blue">
            <p className="text-xs">Balance</p>
            <Button className="rounded px-2 py-1 text-xs">MINT NOW!</Button>
          </div>
        </div>
      </div>

      <Button
        big
        className="w-full p-3 text-base"
        onClick={() => {
          controller?.disconnect([classicNetwork.id])
        }}>
        Disconnect
      </Button>
    </div>
  ) : null
}

const ControllerSection = ({ controller }: { controller: WalletController }) => {
  const [extensionInstalled, setExtensionInstalled] = useState(false)
  useEffect(() => {
    controller.isInstalled('extension').then(setExtensionInstalled)
  }, [controller])
  const [mobileInstalled, setMobileInstalled] = useState(false)
  useEffect(() => {
    controller.isInstalled('walletconnect').then(setMobileInstalled)
  }, [controller])
  return (
    <>
      {extensionInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'extension')}>
          <FaPuzzlePiece className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Extension</p>
        </Button>
      )}
      {mobileInstalled && (
        <Button
          className="flex w-28 flex-col items-center justify-evenly gap-2 rounded-xl py-4"
          onClick={() => connectWallet(controller.id, 'walletconnect')}>
          <FaMobileScreen className="size-8" />
          <p className="font-bold">{capitalize(controller.id)}</p>
          <p className="text-xs">Mobile App</p>
        </Button>
      )}
    </>
  )
}

export const WalletButton = () => {
  const address = useAddress()
  const open = walletState.use.connectModalIsOpen()
  return (
    <>
      <Button className="connect-wallet px-6 py-4 text-base leading-none" onClick={openWalletConnectModal}>
        {address ? t('head.connected') : t('head.connect')}
      </Button>
      <Modal
        open={open}
        onClose={() => closeWalletConnectModal()}
        title="Wallet"
        aside={
          address && (
            <div className="flex w-full min-w-0 max-w-48 flex-row items-center justify-between">
              <span className="min-w-0 max-w-[200px] overflow-hidden text-ellipsis text-nowrap">
                {address.slice(0, -4)}
              </span>
              <span>{address.slice(-4)}</span>
              <button
                className="ml-2 rounded bg-transparent p-0.5 transition focus:bg-blue/10"
                onClick={() => copyToClipboard(address)}>
                <Copy variant="Bold" size={16} />
              </button>
            </div>
          )
        }>
        {address ? (
          <CurrentWallet />
        ) : (
          <>
            <h2>Connect your wallet:</h2>
            <div className="flex flex-wrap gap-6">
              {Object.values(controllers).map((controller) => (
                <ControllerSection key={controller.id} controller={controller} />
              ))}
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
